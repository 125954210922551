<div class="container-fluid">
  <div class="row">
    <div class="d-none d-md-block col-md-5 p-0">
      <div class="left">
        <!-- <img src="../../../../assets/images/fav_icon.jpg" class="logo" alt="" /> -->
      </div>
    </div>
    <div class="col-12 col-md-7 p-0">
      <div class="right">
        <form [formGroup]="loginForm" class="login">
          <div class="loginLogo">
            <img
              src="../../../../assets/images/khLogo.png"
              style="height: 100px"
              alt=""
            />
          </div>

          <div class="login-headering">
            <p>Login</p>
          </div>

          <div class="form-group mt-2 mb-2">
            <label class="bold_">Email</label>
            <input
              type="text"
              formControlName="email"
              autocomplete="current-email"
              class="form-control login_input"
              placeholder="Email "
            />
            <div
              *ngIf="
                loginForm.controls['email'].invalid &&
                (loginForm.controls['email'].dirty ||
                  loginForm.controls['email'].touched ||
                  formSubmitted)
              "
              class="errorinput text-left"
            >
              <div *ngIf="loginForm.controls['email'].errors.required">
                *Email is required.
              </div>
              <div *ngIf="loginForm.controls['email'].errors.pattern">
                *Invalid email .
              </div>
            </div>
          </div>

          <div class="form-group mt-3 mb-2 position-relative">
            <label class="bold_">Password</label>
            <input
              type="password"
              formControlName="password"
              autocomplete="current-password"
              style="padding-right: 50px"
              [type]="show ? 'text' : 'password'"
              class="form-control login_input"
              placeholder="Password"
            />
            <img
              *ngIf="show == false"
              (click)="password()"
              class="show_password"
              src="../../../../assets/img/eye-slash-svgrepo-com.svg"
              alt=""
            />
            <img
              *ngIf="show == true"
              (click)="password()"
              class="show_password"
              src="../../../../assets/img/eye-svgrepo-com.svg"
              alt=""
            />
            <div
              *ngIf="
                loginForm.controls['password'].invalid &&
                (loginForm.controls['password'].dirty ||
                  loginForm.controls['password'].touched ||
                  formSubmitted)
              "
              class="errorinput text-left"
            >
              <div *ngIf="loginForm.controls['password'].errors.required">
                *Password is required.
              </div>
              <div *ngIf="loginForm.controls['password'].errors.minlength">
                *Minimum password length is 6.
              </div>
            </div>
          </div>

          <div class="form-group mt-3 mb-2">
            <label class="bold_">Role</label>
            <select formControlName="role" class="form-control login_input">
              <option value="admin">Admin</option>
              <option value="staff">Staff</option>
            </select>
            <div
              *ngIf="
                loginForm.controls['role'].invalid &&
                (loginForm.controls['role'].dirty ||
                  loginForm.controls['role'].touched ||
                  formSubmitted)
              "
              class="errorinput text-left"
            >
              <div *ngIf="loginForm.controls['role'].errors.required">
                *Role is required.
              </div>
            </div>
          </div>

          <div class="setBtn">
            <div class="pt-3 pb-3" style="width: 155px">
              <button
                class="btn loginBtn w-100"
                [disabled]="!loginForm.valid"
                (click)="login()"
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
