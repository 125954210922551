import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Output,
  Input,
  OnInit,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../../../shared/menu-items/menu-items";
import { CommonService } from "../../../shared/common.service";
import { StreamService } from "../../../../app/shared/stream.service";
import { NavigationStart, Router } from "@angular/router";
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  MENUITEMS = [
    {
      state: "admin-dashboard",
      name: "Dashboard",
      iconUrl: "assets/img/dashboard-black.svg",
      type: "link",
      iconUrlActive: "assets/img/dashboard-white.svg",
    },
    {
      state: "patient/all-patient",
      name: "Patients",
      iconUrl: "assets/img/user-black.svg",
      type: "link",
      iconUrlActive: "assets/img/user-white.svg",
    },
    // { state: 'appointments', name: 'Appointments', iconUrl:"assets/images/store_grey.svg", type: 'link', iconUrlActive:'assets/images/store_white.svg'},

    {
      state: "staff/all-staff",
      name: "Staff",
      iconUrl: "assets/img/subject-black.svg",
      type: "link",
      iconUrlActive: "assets/img/subject-white.svg",
    },

    {
      state: "documents/view",
      name: "Diagnosis",
      iconUrl: "assets/img/study-black.svg",
      type: "link",
      iconUrlActive: "assets/img/study-black@2x.svg",
    },
    {
      state: "complaint/all-Complaints",
      name: "Complaint",
      iconUrl: "assets/img/study-black.svg",
      type: "link",
      iconUrlActive: "assets/img/study-black@2x.svg",
    },
    {
      state: "symptoms/all-Symptoms",
      name: "Symptoms",
      iconUrl: "assets/img/study-black.svg",
      type: "link",
      iconUrlActive: "assets/img/study-black@2x.svg",
    },
    // {
    //   state: "setting",
    //   name: "Setting",
    //   iconUrl: "assets/img/setting-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/setting-white.svg",
    // },
  ];
  MENUSTAFFITEMS = [
    {
      state: "admin-dashboard",
      name: "Dashboard",
      iconUrl: "assets/img/dashboard-black.svg",
      type: "link",
      iconUrlActive: "assets/img/dashboard-white.svg",
    },
    {
      state: "patient/all-patient",
      name: "Patients",
      iconUrl: "assets/img/user-black.svg",
      type: "link",
      iconUrlActive: "assets/img/user-white.svg",
    },
    // {
    //   state: "documents/view",
    //   name: "Diagnosis",
    //   iconUrl: "assets/img/study-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/study-black@2x.svg",
    // },
    // {
    //   state: "complaint/all-Complaints",
    //   name: "Complaint",
    //   iconUrl: "assets/img/study-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/study-black@2x.svg",
    // },
    // { state: 'appointments', name: 'Appointments', iconUrl:"assets/images/store_grey.svg", type: 'link', iconUrlActive:'assets/images/store_white.svg'},

    // {
    //   state: "staff/all-staff",
    //   name: "Staff",
    //   iconUrl: "assets/img/subject-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/subject-white.svg",
    // },

    // {
    //   state: "documents/view",
    //   name: "Symptoms",
    //   iconUrl: "assets/img/study-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/study-black@2x.svg",
    // },
    // {
    //   state: "setting",
    //   name: "Setting",
    //   iconUrl: "assets/img/setting-black.svg",
    //   type: "link",
    //   iconUrlActive: "assets/img/setting-white.svg",
    // },
  ];
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false,
  };
  private modalRef: NgbModalRef;
  @Output() messageEvent = new EventEmitter<string>();
  @Input() isMobileActive: any;
  mobileQuery: MediaQueryList;
  currentNavition = [];
  userData: any;
  role: any;
  private _mobileQueryListener: () => void;
  constructor(
    private modalService: NgbModal,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public common: CommonService,
    private streamService: StreamService,
    public router: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.startsWith("/main")) {
          this.menuItems;
        }
      }
    });
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit() {
    this.userData = this.common.getUser();
    this.role = localStorage.getItem("role");
    if (this.role == "admin") {
      this.currentNavition = this.MENUITEMS;
    } else {
      this.currentNavition = this.MENUSTAFFITEMS;
    }
  }

  sendRouteVal(index) {
    this.streamService.streamMessage(index);
  }
  activeRoute(index) {
    localStorage.setItem("activeIndex", index);
    this.sendRouteVal(index);
  }

  readLocalStorageValue(key: string): number {
    return parseInt(localStorage.getItem(key));
  }
  logOut() {
    // console.log("function called");
    this.common.logOut();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  closeSide() {
    let data = "close";
    this.messageEvent.emit(data);
  }
  closeResult: string;
  logOutPopUp(content, btn) {
    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "sm",
      centered: true,
    };

    btn &&
      btn.parentElement &&
      btn.parentElement.parentElement &&
      btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
