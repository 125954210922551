<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container h-100">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      #snav
      id="snav"
      class="dark-sidebar pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="!mobileQuery.matches"
    >
      <app-sidebar
        *ngIf="isMobile == true"
        [isMobileActive]="isMobile"
        (messageEvent)="receiveMessage(data)"
        (click)="snav.close()"
      ></app-sidebar>

      <!-- <app-sidebar  (messageEvent)="receiveMessage($event)"></app-sidebar> -->

      <app-sidebar *ngIf="isMobile == false"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" style="z-index: 999">
      <div class="page-content">
        <div
          class="row m-0 p-0 w-100 justify-content-between"
          style="background: #ffff"
        >
          <div class="row col-xs-1 align-items-center" style="position: fixed">
            <a
              style="cursor: pointer"
              (click)="snav.toggle()"
              (click)="WindowResize($event)"
              value="sidebarclosed"
            >
              <div
                [ngClass]="{ clicked: windowSize === true }"
                class="fa fa-chevron-right rotate greaterArrow"
                style="color: white"
              ></div
            ></a>
          </div>
          <div class="col-xs-11 p-0 rightdiv">
            <div class="row">
              <div class="welcomeBack">Hey, Welcome Back</div>
              <div class="userName">
                <img
                  src="../../../assets/img/Group 1428.svg"
                  class="profileIcon"
                  alt=""
                />
                <span class="profileDetails"
                  ><p class="m-0 p-0">{{ userData?.email }}</p>
                  <p class="m-0 p-0 admin_data" *ngIf="role == 'admin'">
                    ADMIN
                  </p>
                  <p class="m-0 p-0 admin_data" *ngIf="role != 'admin'">
                    Staff
                  </p>
                </span>
              </div>

              <mat-menu #profile="matMenu" class="mymegamenu">
                <button (click)="logoutUser()" mat-menu-item>
                  <mat-icon [style.color]="'#0C479D'">exit_to_app</mat-icon>
                  Sign Out
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <main style="padding: 15px">
          <router-outlet #page="outlet"></router-outlet>
        </main>
      </div>
    </mat-sidenav-content>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
