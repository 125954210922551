import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./main.component";
import { AuthGuardMain } from "../auth/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuardMain],
    children: [
      {
        path: "admin-dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        // data: { state: 'user' }
      },
      {
        path: "patient",
        loadChildren: () =>
          import(
            "../../../src/app/main/user-management/user-management.module"
          ).then((m) => m.UserManagementModule),
        // data: { state: 'user' }
      },
      {
        path: "staff",
        loadChildren: () =>
          import(
            "../../../src/app/main/staff-managment/staff-managment.module"
          ).then((m) => m.StaffManagmentModule),
        // data: { state: 'user' }
      },
      {
        path: "documents",
        loadChildren: () =>
          import(
            "../../../src/app/main/medical-report/medical-report.module"
          ).then((m) => m.MedicalReportModule),
        // data: { state: 'user' }
      },
      {
        path: "setting",
        loadChildren: () =>
          import("../../../src/app/main/setting/setting.module").then(
            (m) => m.SettingModule
          ),
        // data: { state: 'user' }
      },
      {
        path: "complaint",
        loadChildren: () =>
          import("../../../src/app/main/complaints/complaints.module").then(
            (m) => m.ComplaintsModule
          ),
        // data: { state: 'user' }
      },
      {
        path: "symptoms",
        loadChildren: () =>
          import("../../../src/app/main/symtons/symtons.module").then(
            (m) => m.SymtonsModule
          ),
        // data: { state: 'user' }
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
