import { Injectable, Injector } from "@angular/core";
import { Constants } from "./common.const";
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { IfStmt } from "@angular/compiler";

@Injectable()
export class CommonService extends Constants {
  public httpOptions = {};

  constructor(private injector: Injector) {
    super();
  }

  public get router(): Router {
    return this.injector.get(Router);
  }

  getToken(endPoint: any) {
    let token = localStorage.getItem("token");
    switch (endPoint) {
      case this.basic:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: token,
          }),
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: token,
            }),
          };
        }
        break;
      case this.bearer:
        this.httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        };
        if (token) {
          this.httpOptions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              Authorization: token,
            }),
          };
        }
        break;
      default:
        break;
    }
    return this.httpOptions;
  }

  setStorage(key, value): any {
    if (typeof value == "object") {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  getStorage(key): any {
    const x = localStorage.getItem(key);
    try {
      const xx = JSON.parse(x);
      return xx;
    } catch (e) {
      return x;
    }
  }
  removeStorage(key): any {
    localStorage.removeItem(key);
  }

  getUser() {
    const token = this.getStorage("token");
    // const userData = token;
    const userData = jwt_decode(token);
    return userData;
  }
  // getTokenRole() {
  //   const token = this.getStorage("token");
  //   const tokenRole = jwt_decode(token);
  //   return tokenRole;
  // }

  isAuthenticated() {
    if (localStorage.getItem("token") == null) {
      return false;
    } else {
      return true;
    }
  }

  logOut() {
    localStorage.removeItem("token");
    this.router.navigate(["signin"]);
  }
}
