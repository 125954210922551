import { MediaMatcher } from "@angular/cdk/layout";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MenuItems } from "../../shared/menu-items/menu-items";
import { CommonService } from "../../shared/common.service";

import { StreamService } from "../../shared/stream.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

declare var $: any;
/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: ["full.component.scss"],
})
export class FullComponent implements OnDestroy, AfterViewInit, OnInit {
  mobileQuery: MediaQueryList;
  message: number;
  public screenWidth: any;
  public screenHeight: any;
  isMobile: boolean = false;
  userData: any;
  currentTab = "Dashboard";
  searchText = "";
  role: any;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public common: CommonService,
    public router: Router,
    private route: ActivatedRoute,
    private streamService: StreamService,
    public modalService: NgbModal
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentTab(event.urlAfterRedirects);
      }
    });

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.updateCurrentTab(this.router.url);
    this.role = localStorage.getItem("role");
    this.userData = this.common.getUser();
  }
  currentPage: number = 0;
  changefiler() {
    this.currentPage = 0;
  }
  changetext(event) {
    if (this.searchText == "") this.changefiler();
  }

  updateCurrentTab(url: string) {
    switch (url) {
      case "/patient/all-patient":
        this.currentTab = "Users";
        break;
      case "/subscriptions/view":
        this.currentTab = "Subscriptions";
        break;
      case "/documents":
        this.currentTab = "Health Documents";
        break;
      default:
        this.currentTab = "Dashboard";
        break;
    }
  }

  logoutUser() {
    this.common.logOut();
  }
  windowSize: Boolean = false;
  WindowResize(e) {
    this.windowSize = !this.windowSize;
    console.log("this is calling", this.windowSize);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.modalService.dismissAll();
  }

  readLocalStorageValue(key: string): number {
    return parseInt(localStorage.getItem(key));
  }
  logOut() {
    this.common.logOut();
  }
  ngAfterViewInit() {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth <= 780) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  receiveMessage(data) {
    console.log("was here");
  }
}
