<div
  style="
    align-items: center;
    height: 100vh;
    justify-content: center;
    display: flex;
  "
>
  <div
    class="card"
    style="
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 10px;
    "
  >
    <h2 style="text-align: center">Support</h2>
    <img
      style="width: 80px"
      src="../../assets/img/khLogo.png"
      alt="Card image cap"
    />

    <div class="card-body">
      <div style="text-align: center">
        <p>Contact Us:</p>
        <p>
          Email:
          <span
            ><a href="mailto:support@wangwang.com"
              >support@wangwang.com</a
            ></span
          >
        </p>
        <p>
          Phone: <span><a href="tel:+1234567890">+1 (162) 588-6500</a></span>
        </p>
      </div>
    </div>
  </div>
</div>
